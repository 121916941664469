import React from "react";
import SpotlightContainer from "../spotlight";
import UiPath from "../../../images/uipath.png";
import Image from "../../../images/pa1.png";

function Project() {
  const jobData = {
    jobName: "Automated Stocks Analysis",
    button: "Download",
    link: "https://drive.google.com/drive/folders/11N-WU9Hv-ysSQUr4AW3Qo_tUpNi2eOcx?usp=sharing",
    image: Image,
    stack: [
      {
        name: "UiPath",
        image: UiPath,
      },
    ],

    description:
      "This is a robust RPA bot using UiPath that automates the end-to-end process of stock analysis and reporting. The bot initiates by collecting a ticker symbol, then navigates to Google Finance to retrieve relevant stock data. After gathering the data, it prompts ChatGPT for advanced analysis. Once the analysis is completed, the bot composes and sends a detailed report via Outlook Web.",
    roles: [],
  };

  return <SpotlightContainer jobData={jobData} />;
}
export default Project;
